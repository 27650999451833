@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

img {
  max-width: 100%;
  max-height: 60%;
}
.modelDiv {
  width: 100%;
  height: 100%;
  position: absolute;
}

.arrowIcon {
  cursor: pointer;
  filter: drop-shadow(0px 0px .1px rgba(248, 248, 248, 1));
}

.topDiv {
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  height: 15%;
  background: rgba(248, 248, 248, 0.5);
  width: fit-content;
}

.btnBottomDiv {
  position: absolute;
  bottom: 0;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnBottomDiv button {
  font-family: "Roboto Light", sans-serif;
  background-color: #024959;
  color: #f2e3d5;
  accent-color: #878787;
}

.btnAnimation {
  width: 200px;
  height: 50px;
  /* margin-left: 1rem !important;
  margin-right: 1rem !important;
  margin-bottom: 1rem !important; */
}

.btnBottomDiv button:hover {
  font-family: "Roboto Light", sans-serif;
  background-color: #026773;
  color: #f2e3d5;
  accent-color: #878787;
}

@media only screen and (max-width: 420px) {
  .topDiv {
    align-items: center;
  }
}
@media only screen and (max-width: 768px) {
  .btnBottomDiv {
    padding: 10px;
    display: block;
    text-align: center;
  }

  .btnAnimation {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
/* HOME */

.homepageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: black;
}

.homepageDiv h2 {
  color: hsla(0, 0%, 0%, 0.9);
  font: normal 140px Varela Round, sans-serif;
  height: 140px;
  left: 0;
  letter-spacing: 5px;
  margin: -80px 0 0 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 50%;
  width: 100%;
  animation: move linear 2000ms infinite;
}

@keyframes move {
  0% {
    text-shadow: 4px -4px 0 hsla(0, 100%, 50%, 1),
      3px -3px 0 hsla(0, 100%, 50%, 1), 2px -2px 0 hsla(0, 100%, 50%, 1),
      1px -1px 0 hsla(0, 100%, 50%, 1), -4px 4px 0 hsla(180, 100%, 50%, 1),
      -3px 3px 0 hsla(180, 100%, 50%, 1), -2px 2px 0 hsla(180, 100%, 50%, 1),
      -1px 1px 0 hsla(180, 100%, 50%, 1);
  }
  25% {
    text-shadow: -4px -4px 0 hsla(180, 100%, 50%, 1),
      -3px -3px 0 hsla(180, 100%, 50%, 1), -2px -2px 0 hsla(180, 100%, 50%, 1),
      -1px -1px 0 hsla(180, 100%, 50%, 1), 4px 4px 0 hsla(0, 100%, 50%, 1),
      3px 3px 0 hsla(0, 100%, 50%, 1), 2px 2px 0 hsla(0, 100%, 50%, 1),
      1px 1px 0 hsla(0, 100%, 50%, 1);
  }
  50% {
    text-shadow: -4px 4px 0 hsla(0, 100%, 50%, 1),
      -3px 3px 0 hsla(0, 100%, 50%, 1), -2px 2px 0 hsla(0, 100%, 50%, 1),
      -1px 1px 0 hsla(0, 100%, 50%, 1), 4px -4px 0 hsla(180, 100%, 50%, 1),
      3px -3px 0 hsla(180, 100%, 50%, 1), 2px -2px 0 hsla(180, 100%, 50%, 1),
      1px -1px 0 hsla(180, 100%, 50%, 1);
  }
  75% {
    text-shadow: 4px 4px 0 hsla(180, 100%, 50%, 1),
      3px 3px 0 hsla(180, 100%, 50%, 1), 2px 2px 0 hsla(180, 100%, 50%, 1),
      1px 1px 0 hsla(180, 100%, 50%, 1), -4px -4px 0 hsla(0, 100%, 50%, 1),
      -3px -3px 0 hsla(0, 100%, 50%, 1), -2px -2px 0 hsla(0, 100%, 50%, 1),
      -1px -1px 0 hsla(0, 100%, 50%, 1);
  }
  100% {
    text-shadow: 4px -4px 0 hsla(0, 100%, 50%, 1),
      3px -3px 0 hsla(0, 100%, 50%, 1), 2px -2px 0 hsla(0, 100%, 50%, 1),
      1px -1px 0 hsla(0, 100%, 50%, 1), -4px 4px 0 hsla(180, 100%, 50%, 1),
      -3px 3px 0 hsla(180, 100%, 50%, 1), -2px 2px 0 hsla(180, 100%, 50%, 1),
      -1px 1px 0 hsla(180, 100%, 50%, 1);
  }
}

/* MODEL CONTROL BUTTON */
